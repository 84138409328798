<template>
  <div>
    <el-dialog
      title="카메라 제어"
      :visible.sync="liveViewDialog"
      @open="cctvModalOpen"
      @close="cctvModalClose"
      append-to-body
      top="5vh"
      class="el-dialog-aside"
      width="1128px"
    >
      <el-container>
        <el-aside width="350px">
          <div class="info-panel">
            <div class="info-panel-wrap">
              <el-table :data="fcltList" size="mini" stripe @row-click="selectData">
                <el-table-column width="80px" prop="fcltId" label="시설물 ID"></el-table-column>
                <el-table-column prop="fcltName" label="시설물 명칭"></el-table-column>
                <el-table-column prop="fcltTypeName" label="시설물 유형"></el-table-column>
              </el-table>
              <dk-el-pagination
                :totalDocs="totalDocs"
                :limit="limit"
                :pagingProc="pagingProc"
                ref="pagination"
              ></dk-el-pagination>
            </div>
          </div>
        </el-aside>
        <el-container>
          <el-main>
            <div>
              <dk-hive-webrtc-player
                :server_ip="webRtcInfo.iP"
                :server_port="webRtcInfo.port"
                :video_name="webRtcInfo.videoName"
                ref="dkHiveWebrtcPlayer"
              ></dk-hive-webrtc-player>
            </div>

            <div class="camera-status-monitoring-wrap">
              <ul class="camera-status-monitoring">
                <li>
                  <span>{{selectedCctv.fcltName}}</span>
                </li>
                <li>
                  pan
                  <span>{{pan}}</span>
                </li>
                <li>
                  tilt
                  <span>{{tilt}}</span>
                </li>
                <li>
                  zoom
                  <span>{{zoom}}</span>
                </li>
              </ul>
            </div>
            <div class="camera-info-wrap">
              <div class="camera-preset-list-wrap">
                <el-table
                  ref="singleTable"
                  :data="presetInfo"
                  highlight-current-row
                  @current-change="handleCurrentChange"
                  style="width: 100%; height:220px;"
                  size="mini"
                  class="preset-table"
                  height="220px"
                >
                  <!-- <el-table-column
                                    prop="CCTV_MNGM_NMBR"
                                    label="번호"
                                    align="center"
                                    width="45px">
                                    </el-table-column>

                                    <el-table-column
                                    label="프리셋명"
                                    width="170px"
                                    align="center"
                                    >
                                        <template slot-scope="scope">
                                            <td><el-input placeholder="프리셋번호" v-model="scope.row.PSET_NM" size="mini"></el-input></td>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                    prop="PAN_VAL"
                                    label="PAN"
                                    align="center"
                                    width="60px">
                                    </el-table-column>

                                    <el-table-column
                                    prop="TILT_VAL"
                                    label="TILT"
                                    align="center"
                                    width="60px">
                                    </el-table-column>

                                    <el-table-column
                                    prop="ZOOM_VAL"
                                    label="ZOOM"
                                    align="center"
                                    width="60px">    
                                    </el-table-column>

                                    <el-table-column
                                    prop="FOCS_VAL"
                                    label="FOCUS"
                                    align="center"
                                    width="65px">
                                    </el-table-column>
                                    
                                    <el-table-column
                                    label="대표프리셋"
                                    width="80"
                                    align="center"
                                    >
                                    <template slot-scope="scope" >
                                        <td @click="changeDefault()"><el-checkbox :max="1" v-model="scope.row.DFLT_YN"></el-checkbox></td>
                                        </template>
                  </el-table-column>-->

                  <el-table-column label="번호" width="45px" align="center">
                    <template slot-scope="scope" style="height=100%">{{ scope.row.presetNo }}</template>
                  </el-table-column>

                  <el-table-column label="프리셋명" width="150px" align="center">
                    <template slot-scope="scope">
                      <td>
                        <el-input placeholder="프리셋명" v-model="scope.row.presetName" size="mini"></el-input>
                      </td>
                    </template>
                  </el-table-column>

                  <el-table-column label="PAN" width="60px" align="center">
                    <template slot-scope="scope">{{ scope.row.presetPtz.pan }}</template>
                  </el-table-column>
                  <el-table-column label="TILT" width="60px" align="center">
                    <template slot-scope="scope">{{ scope.row.presetPtz.tilt }}</template>
                  </el-table-column>
                  <el-table-column label="ZOOM" width="60px" align="center">
                    <template slot-scope="scope">{{ scope.row.presetPtz.zoom }}</template>
                  </el-table-column>
                  <el-table-column label="대표프리셋" width="80px" align="center">
                    <template slot-scope="scope">
                      <td @click="changeDefault()">
                        <el-checkbox :max="1" v-model="scope.row.mainPreset"></el-checkbox>
                      </td>
                    </template>
                  </el-table-column>
                </el-table>
                <div style="float: right">
                  <el-button @click="presetSave()">등록</el-button>
                  <el-button @click="presetMove()">이동</el-button>
                  <el-button @click="presetDelete()">삭제</el-button>
                </div>
              </div>
              <div class="cctv-control-wrap">
                <el-tabs v-model="activeName">
                  <el-tab-pane label="PTZ제어" name="first">
                    <table style="width:100%">
                      <colgroup>
                        <col style="width:20%" />
                        <col style="width:20%" />
                        <col style="width:20%" />
                        <col style="width:20%" />
                        <col style="width:20%" />
                      </colgroup>
                      <tr>
                        <td @mousedown="downContinusMove(5)" @mouseup="upContinusMove()">
                          <el-button style="width:100%">
                            <i class="fas fa-search-plus"></i>
                          </el-button>
                        </td>
                        <td>&nbsp;</td>
                        <td @mousedown="downContinusMove(1)" @mouseup="upContinusMove()">
                          <el-button style="width:100%">
                            <i class="fas fa-arrow-up"></i>
                          </el-button>
                        </td>
                        <td>&nbsp;</td>
                        <td @mousedown="downContinusMove(7)" @mouseup="upContinusMove()">
                          <el-button style="width:100%">
                            <i class="fas fa-plus-circle"></i>
                          </el-button>
                        </td>
                      </tr>
                      <tr>
                        <td>Zoom</td>
                        <td @mousedown="downContinusMove(2)" @mouseup="upContinusMove()">
                          <el-button style="width:100%">
                            <i class="fas fa-arrow-left"></i>
                          </el-button>
                        </td>
                        <td>
                          <el-input v-model="controlSpeed" :disabled="true"></el-input>
                        </td>
                        <td @mousedown="downContinusMove(3)" @mouseup="upContinusMove()">
                          <el-button style="width:100%">
                            <i class="fas fa-arrow-right"></i>
                          </el-button>
                        </td>
                        <td>Focus</td>
                      </tr>
                      <tr>
                        <td @mousedown="downContinusMove(6)" @mouseup="upContinusMove()">
                          <el-button style="width:100%">
                            <i class="fas fa-search-minus"></i>
                          </el-button>
                        </td>
                        <td>&nbsp;</td>
                        <td @mousedown="downContinusMove(4)" @mouseup="upContinusMove()">
                          <el-button style="width:100%">
                            <i class="fas fa-arrow-down"></i>
                          </el-button>
                        </td>
                        <td>&nbsp;</td>
                        <td @mousedown="downContinusMove(8)" @mouseup="upContinusMove()">
                          <el-button style="width:100%">
                            <i class="fas fa-minus-circle"></i>
                          </el-button>
                        </td>
                      </tr>
                    </table>
                    <div class="control-slider-wrap">
                      <span class="camera-control-speed-label">제어속도</span>
                      <span class="camera-control-slider">
                        <el-slider v-model="controlSpeed" :max="64"></el-slider>
                      </span>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </el-main>
          <!-- <el-aside>
                      <div class="info-panel">
                          <div class="info-panel-wrap">
                              <div style= "margin-left : 15px">
                                  <el-button type="primary" @click="cameraReset('하드')">H.W Reset</el-button>
                                  <el-button type="primary" @click="cameraReset('소프트')">S.W Reset</el-button>
                              </div>
                              <div style= "padding:5px">
                                  <div style ="margin-top : 15px">
                                      <tr>
                                          Pan<td><el-input v-model="pan" :disabled="true"></el-input></td>
                                          Tilt<td><el-input v-model="tilt" :disabled="true"></el-input></td>
                                      </tr>
                                      <tr>
                                          Zoom<td><el-input  v-model="zoom" :disabled="true"></el-input></td>
                                          Focus<td><el-input v-model="focus" :disabled="true"></el-input></td>
                                      </tr>
                                  </div>
                              </div>
                              <div style ="margin-top : 30px">
                                  <table style="width:100%">
                                      <tr>
                                          <td style="width:33%;">
                                              
                                          </td>
                                          <td style="width:33%;" @mousedown="downContinusMove(1)" @mouseup="upContinusMove()">
                                              <el-button style="width:100%" ><i class="fas fa-arrow-up"></i></el-button>
                                          </td>
                                          <td style="width:33%;">
                                              
                                          </td>
                                      </tr>
                                      <tr>
                                          <td style="width:33%;" @mousedown="downContinusMove(2)" @mouseup="upContinusMove()">
                                              <el-button style="width:100%"><i class="fas fa-arrow-left"></i></el-button>
                                          </td>                    
                                          <td style="width:33%;"> 
                                          </td>                                    
                                          <td style="width:33%;" @mousedown="downContinusMove(3)" @mouseup="upContinusMove()"> 
                                              <el-button style="width:100%"><i class="fas fa-arrow-right"></i></el-button>
                                          </td>                        
                                      </tr>                
                                      <tr>
                                          <th style="width:33%;">
                                              
                                          </th>
                                          <th style="width:33%;" @mousedown="downContinusMove(4)" @mouseup="upContinusMove()">
                                              <el-button style="width:100%"><i class="fas fa-arrow-down"></i></el-button>
                                          </th>
                                          <th style="width:33%;">
                                              
                                          </th>                        
                                      </tr>
                                  </table>
                                  <table style="width:100%; margin-top:20px;">
                                      <tr>
                                          <td>제어 속도</td>
                                      </tr>
                                      <tr>
                                          <td colspan="3">
                                              <el-slider v-model="controlSpeed" :max = '64'></el-slider>
                                          </td>
                                          <td>
                                              <el-input v-model="controlSpeed" :disabled="true"></el-input>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>Zoom</td>
                                          <td></td>
                                          <td>Focus</td>
                                      </tr>
                                      <tr>
                                          <td style="width:33%;" @mousedown="downContinusMove(5)" @mouseup="upContinusMove()">
                                              <el-button style="width:100%" ><i class="fas fa-search-plus"></i></el-button>
                                          </td>
                                          <td style="width:33%;" @mousedown="downContinusMove(6)" @mouseup="upContinusMove()">
                                              <el-button style="width:100%" ><i class="fas fa-search-minus"></i></el-button>
                                          </td>
                                          <td style="width:33%;" @mousedown="downContinusMove(7)" @mouseup="upContinusMove()">
                                              <el-button style="width:100%" ><i class="fas fa-plus-circle"></i></el-button>
                                          </td>
                                          <td style="width:33%;" @mousedown="downContinusMove(8)" @mouseup="upContinusMove()">
                                              <el-button style="width:100%" ><i class="fas fa-minus-circle"></i></el-button>
                                          </td>
                                      </tr>
                                  </table>
                              </div>
                              <div style ="margin-top : 20px">
                                  온도
                              </div>
                              <div style ="margin-top : 5px">
                                  <tr>
                                      <td><el-input placeholder="FAN ON" v-model="fanOn"></el-input></td>
                                      <td><el-input placeholder="FAN OFF" v-model="fanOff"></el-input></td>
                                  </tr>
                                  <tr>
                                      <td><el-input placeholder="HEATER ON" v-model="heaterOn"></el-input></td>
                                      <td><el-input placeholder="HEATER OFF" v-model="heaterOff"></el-input></td>
                                  </tr>
                                  <tr><td><el-button>가변문자 발생기</el-button></td>
                                      <td align="right"><el-button @click="setParameter()">설정</el-button></td>
                                  </tr>

                              </div>
                          </div>
                      </div>
          </el-aside>-->
        </el-container>
      </el-container>
    </el-dialog>
    <div style="margin-bottom:8px;">
      <el-button @click="liveViewDialogOpen">
        <i class="fas fa-video"></i>&nbsp;카메라 제어
      </el-button>
    </div>
    <!--div class="dk-card">
                <div class="dk-card-title">
                    CCTV 상태 정보
                </div>
                <table class="el-table el-table--mini">
                    <tr>
                        <th style="width:25%;text-align:center;">
                            통신 상태
                        </th>
                        <th style="width:25%;text-align:center;">
                            함체 문열림
                        </th>
                        <th style="width:25%;text-align:center;">
                            함체 팬 동작
                        </th>
                        <th style="width:25%;text-align:center;">
                            함체 히터 동작
                        </th>                        
                    </tr>
                    <tr>   
                        <td  v-if="this.tcpConnecting == 1" style="width:25%; text-align:center;"> 
                            <el-tag type="success" >정상</el-tag>
                        </td>
                        <td v-else style="width:25%; text-align:center;">
                            <el-tag type="danger">비정상</el-tag>
                        </td> 

                        <td  v-if="this.doorStatus == 0" style="width:25%; text-align:center;"> 
                            <el-tag type="danger" >열림</el-tag>
                        </td>
                        <td v-else style="width:25%; text-align:center;"> 
                            <el-tag type="success">닫힘</el-tag>
                        </td>   

                        <td v-if="this.heaterStatus == 1" style="width:25%; text-align:center;">
                            <el-tag type="success">가동</el-tag>
                        </td>
                        <td v-else style="width:25%; text-align:center;">
                            <el-tag type="success">비가동</el-tag>
                        </td>

                        <td  v-if="this.panStatus == 1" style="width:25%; text-align:center;">
                            <el-tag type="success">가동</el-tag>
                        </td>       
                        <td v-else style="width:25%; text-align:center;">
                            <el-tag type="success">비가동</el-tag>
                        </td>                  
                    </tr>                
                </table>
    </div-->
    <!-- <fclt-plugin-status
                ref="fcltPluginStatus"
                :title="'CCTV 상태정보'"
                :statHederInfo="statHederInfo"
                :fcltId="this.fcltData.fcltId"
            >
    </fclt-plugin-status>-->
  </div>
</template>
<script>
import dkelpagination from "@/components/dkElPagination.vue";
import fcltPluginStatus from "@/traffic/widget/fcltPluginStatus.vue";
import dkHiveWebRtcPlayer from "@/components/webrtc/dkHiveWebRtcPlayer.vue";
export default {
  data() {
    return {
      activeName: "first",
      fcltList: [],
      limit: 10,
      totalDocs: 0,
      liveViewDialog: false,
      presetInfo: [],
      parameterInfo: {},
      fanOn: "",
      fanOff: "",
      heaterOn: "",
      heaterOff: "",
      selectedCctv: null,
      controlSpeed: 30,
      pan: 0,
      tilt: 0,
      zoom: 0,
      focus: 0,
      joinCctvId: "",
      // shinobiCameraId:"",
      // dkWowzaCameraId:"",
      defaultPreset: "",
      statusIntervalKey: -1,
      doorStatus: 1,
      heaterStatus: 1,
      panStatus: 1,
      tcpConnecting: 1,
      ptzStatus: "",
      rowClick: "",
      // statKeyWords: [
      //         "powerStatus",
      //         "doorStatus",
      //         "fanStatus",
      //         "heaterStatus",
      // ],
      statHederInfo: [
        {
          key: "powerStatus",
          name: "모듈 전원",
          textInfo: {
            ERR: "에러",
            OFF: "꺼짐",
            ON: "정상"
          }
        },
        {
          key: "doorStatus",
          name: "문 열림",
          textInfo: {
            ERR: "에러",
            OFF: "꺼짐",
            ON: "정상"
          }
        },
        {
          key: "fanStatus",
          name: "팬 가동",
          textInfo: {
            ERR: "에러",
            OFF: "꺼짐",
            ON: "정상"
          }
        },
        {
          key: "heaterStatus",
          name: "히터 가동",
          textInfo: {
            ERR: "에러",
            OFF: "꺼짐",
            ON: "정상"
          }
        }
      ],
      webRtcInfo: {
        iP: "",
        port: "",
        videoName: ""
      }
    };
  },
  props: {
    fcltData: {
      required: true,
      default: {}
    }
  },
  components: {
    "dk-el-pagination": dkelpagination,
    "fclt-plugin-status": fcltPluginStatus,
    "dk-hive-webrtc-player": dkHiveWebRtcPlayer
  },
  name: "dk-road-cctv",
  label: "CAMERA",
  created() {
    let me = this;
    me.selectedCctv = me.fcltData;
    !me.fcltData.fcltTypeData.webRtcIp ||
      (me.webRtcInfo.iP = me.fcltData.fcltTypeData.webRtcIp.value);
    !me.fcltData.fcltTypeData.webRtcPort ||
      (me.webRtcInfo.port = me.fcltData.fcltTypeData.webRtcPort.value);
    !me.fcltData.fcltTypeData.webRtcVideoName ||
      (me.webRtcInfo.videoName =
        me.fcltData.fcltTypeData.webRtcVideoName.value);

    this.getFclts(1);
  },

  mounted() {
    this.$nextTick(() => {
      this.initCctvPlugin();
    });
  },
  destroyed() {
    //   clearInterval(this.statusIntervalKey)
  },

  methods: {
    async initCctvPlugin() {
      try {
        this.joinCctvId = this.fcltData.fcltId;
        //   this.$refs.fcltPluginStatus.updateFcltStatusInfo(this.joinCctvId);
        await this.presetLoad();
        await this.ptzLoad();
      } catch (err) {
        console.log(err);
      }
    },
    async selectData(row) {
      try {
        if (this.selectedCctv.fcltId === row.fcltId) {
          return;
        }
        this.selectedCctv = row;
        //   this.$refs.dkHiveWebrtcPlayer.stop()
        this.joinCctvId = row.fcltId;
        this.activeName = "first";
        let webrtcParam = {};
        !this.selectedCctv.fcltTypeData.webRtcIp ||
          (webrtcParam.ip = this.selectedCctv.fcltTypeData.webRtcIp.value);
        !this.selectedCctv.fcltTypeData.webRtcPort ||
          (webrtcParam.port = this.selectedCctv.fcltTypeData.webRtcPort.value);
        !this.selectedCctv.fcltTypeData.webRtcVideoName ||
          (webrtcParam.videoName = this.selectedCctv.fcltTypeData.webRtcVideoName.value);
        this.$refs.dkHiveWebrtcPlayer.play(webrtcParam);

        this.$emit("updatePlugin", this.selectedCctv);

        await this.presetLoad();
        await this.ptzLoad();
      } catch (err) {
        console.log(err);
      }
    },
    cctvModalOpen() {
      this.selectedCctv = this.fcltData;

      // let me = this
      // if(this.statusIntervalKey !== -1) {
      //         clearInterval(this.statusIntervalKey);
      //         this.statusIntervalKey = -1;
      //     }
      //     this.statusIntervalKey = setInterval(function() {
      //                 me.parameterLoad();
      //                 }, 30*1000)
      setTimeout(() => {
        let webrtcParam = {};
        !this.selectedCctv.fcltTypeData.webRtcIp ||
          (webrtcParam.ip = this.selectedCctv.fcltTypeData.webRtcIp.value);
        !this.selectedCctv.fcltTypeData.webRtcPort ||
          (webrtcParam.port = this.selectedCctv.fcltTypeData.webRtcPort.value);
        !this.selectedCctv.fcltTypeData.webRtcVideoName ||
          (webrtcParam.videoName = this.selectedCctv.fcltTypeData.webRtcVideoName.value);
        this.$refs.dkHiveWebrtcPlayer.play(webrtcParam);
      }, 500);
    },
    cctvModalClose() {
      this.$refs.dkHiveWebrtcPlayer.stop();
    },
    getFclts(pageNum) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit
      };
      if (this.keyword !== "" && this.keyword) {
        param.keyword = this.keyword;
      } else {
        param.fcltTypeId = "FCLTTYPE10005";
      }
      this.$store.dispatch("fclt/getFclt", param).then(() => {
        me.fcltList = me.$store.getters["fclt/getFclt"];
        me.totalDocs = me.$store.getters["fclt/getPagination"].totalDocs;
      });
    },
    cameraReset(resetVal) {
      var resetValue;
      var cctvId = this.joinCctvId;
      if (resetVal == "소프트") {
        resetValue = 1;
      } else {
        resetValue = 2;
      }
      this.$confirm(resetVal + "리셋하시겠습니까?", "Warning", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        type: "warning"
      })
        .then(() => {
          if (resetValue == 1) {
            this.$store.dispatch("gimpoCamera/swReset", {
              swValue: this.joinCctvId
            });
          } else {
            this.$store.dispatch("gimpoCamera/hwReset", {
              hwValue: this.joinCctvId
            });
          }
          this.$message({
            type: "success",
            message: "리셋 완료."
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "리셋 취소."
          });
        });
    },

    pagingProc(pageNum) {
      this.getFclts();
    },
    handleCurrentChange(val) {
      this.rowClick = val;
      console.log(this.rowClick);
    },
    presetSave() {
      let me = this;
      let param = {
        cameraId: this.rowClick.cameraId,
        presetNo: this.rowClick.presetNo,
        presetName: this.rowClick.presetName,
        mainPreset: this.rowClick.mainPreset
      };
      this.$store.dispatch("gimpoCamera/addGimpoPreset", { param }).then(() => {
        this.checkPreset(1);
      });
    },
    checkPreset(val) {
      this.presetLoad();
      this.presetInfo;
      if (val == 1) {
        return alert("프리셋 등록 완료");
      } else if (val == 2) {
        alert("프리셋 삭제 완료");
      } else {
        alert("실패");
      }
    },
    presetMove() {
      let me = this;
      let param = {
        cameraId: this.rowClick.cameraId,
        presetNo: this.rowClick.presetNo
      };

      this.$store
        .dispatch("gimpoCamera/moveGimpoPreset", { param })
        .then(() => {
          let presetMove = me.$store.getters["gimpoCamera/moveGimpoPreset"];
        });
    },
    presetDelete() {
      let me = this;
      let param = {
        cameraId: this.rowClick.cameraId,
        presetNo: this.rowClick.presetNo
      };

      this.$confirm(
        param.presetNo + "번 프리셋을 삭제하시겠습니까?",
        "Warning",
        {
          confirmButtonText: "삭제",
          cancelButtonText: "취소",
          type: "warning"
        }
      ).then(() => {
        this.$store.dispatch("gimpoCamera/deleteGimpoPreset", { param });
        this.checkPreset(2);
      });
    },
    downContinusMove(val) {
      let me = this;
      let param = {
        cameraId: this.joinCctvId,
        pan: 0,
        tilt: 0,
        zoom: 0,
        focus: 0
      };
      if (val == 1) {
        param.tilt = 1 * this.controlSpeed;
      } else if (val == 2) {
        param.pan = -1 * this.controlSpeed;
      } else if (val == 3) {
        param.pan = 1 * this.controlSpeed;
      } else if (val == 4) {
        param.tilt = -1 * this.controlSpeed;
      } else if (val == 5) {
        param.zoom = 2;
      } else if (val == 6) {
        param.zoom = -2;
      } else if (val == 7) {
        param.focus = 2;
      } else if (val == 8) {
        param.focus = -2;
      }

      this.$store.dispatch("gimpoCamera/cameraContinusMove", { param: param });
      // .then(() => {

      //     me.presetInfo = me.$store.getters['gimpoCamera/cameraContinusMove']
      // })
    },
    async upContinusMove() {
      //마우스업 했을때 현 pan , tilt , zoom 받아오기
      let statusInfo = {};
      let param = {
        cameraId: this.joinCctvId,
        pan: 0,
        tilt: 0,
        zoom: 0,
        focus: 0
      };

      await this.$store.dispatch("gimpoCamera/cameraContinusMove", {
        param: param
      });
      this.ptzLoad();
    },
    setParameter() {
      let param = {
        cctvId: this.joinCctvId,
        fanOnTemp: this.fanOn,
        fanOffTemp: this.fanOff,
        heaterOnTemp: this.heaterOn,
        heaterOffTemp: this.heaterOff
      };
      this.$store
        .dispatch("gimpoCamera/setParameter", { parameterValue: param })
        .then(() => {
          this.parameterLoad();
          alert("파라미터 설정 완료");
        });
    },
    presetDefault() {
      // this.joinCctvId

      var params = {
        cctvId: this.selectedCctv.CCTV_MNGM_NMBR,
        presetNo: this.selectedCctv.PSET_NMBR
      };

      this.$store
        .dispatch("gimpoCamera/defaultPreset", { presetSetDFLTValue: params })
        .then(() => {
          alert("대표프리셋 설정완료");
        });
    },

    async presetLoad() {
      let presetData = [];
      let me = this;
      if (me.presetInfo.length > 0) {
        me.presetInfo = [];
      }
      await me.$store.dispatch("gimpoCamera/gimpoPresetInfo", {
        cameraId: this.joinCctvId
      });
      presetData = me.$store.getters["gimpoCamera/gimpoPresetInfo"];
      if (!presetData) {
        return;
      }
      for (var i = 0; i < presetData.length; i++) {
        me.presetInfo.push(presetData[i]);
      }
    },

    async parameterLoad() {
      let parameterData = [];
      await this.$store.dispatch("gimpoCamera/gimpoParameterInfo", {
        cctvId: this.joinCctvId
      });
      if (parameterData == undefined || parameterData == "null") {
        parameterData = this.$store.getters["gimpoCamera/gimpoParameterInfo"];
        this.fanOn = parameterData.parameterInfo.fanOnTemperature;
        this.fanOff = parameterData.parameterInfo.fanOffTemperature;
        this.heaterOn = parameterData.parameterInfo.heaterOnTemperature;
        this.heaterOff = parameterData.parameterInfo.heaterOffTemperature;
        this.doorStatus = parameterData.status.DoorOpenClose;
        this.heaterStatus = parameterData.status.heaterOnOff;
        this.panStatus = parameterData.status.panOnOff;
      }
    },

    async ptzLoad() {
      let statusInfo = {};
      let statusCctvId = this.fcltData.fcltId;
      let param = {
        cameraId: statusCctvId,
        statusSync: 0,
        statusEvent: 1
      };
      await this.$store.dispatch("gimpoCamera/getPtz", {
        cameraId: statusCctvId
      });
      statusInfo = this.$store.getters["gimpoCamera/getPtz"];
      if (statusInfo.statusFlag == false) {
        alert("카메라 응답없음");
        this.cctvValueReset();
      } else {
        this.pan = statusInfo.pan;
        this.tilt = statusInfo.tilt;
        this.zoom = statusInfo.zoom;
        this.focus = statusInfo.focus;
      }
    },

    changeDefault() {
      console.log(this.selectedCctv);
      if (this.selectedCctv != "") {
        if (this.defaultPreset.PSET_NMBR == this.selectedCctv.PSET_NMBR) {
          console.log("똑같은 프리셋 클릭함.");
        } else {
          this.defaultPreset.DFLT_YN = false;
          this.selectedCctv.DFLT_YN = true;
          this.defaultPreset = this.selectedCctv;
        }
      }
    },
    cctvValueReset() {
      this.pan = 0;
      this.tilt = 0;
      this.zoom = 0;
      this.focus = 0;
      this.fanOn = 0;
      this.fanOff = 0;
      this.heaterOn = 0;
      this.heaterOff = 0;
      this.tcpConnecting = 0;
    },
    liveViewDialogOpen() {
      this.liveViewDialog = true;
      // this.$nextTick(()=> {
    }
  }
};
</script>

<style scoped>
.detect-data-table img {
  width: 100%;
  height: auto;
}

.detect-canvas {
  width: 100%;
  height: auto;
  border-radius: 3px;
}

.el-table--mini td {
  padding: 3px 0 !important;
}

.preset-table td,
.preset-table {
  padding: 2px 0 !important;
}

.camera-status-monitoring-wrap {
  background-color: #000;
  height: 40px;
  position: relative;
}

.camera-status-monitoring {
  list-style: none;
  margin: 0;
  position: absolute;
  right: 0px;
  top: 10px;
}
.camera-status-monitoring:after {
  display: block;
  content: "";
  clear: both;
}
.camera-status-monitoring li {
  float: left;
  margin: 2px 8px;
  color: #cfcfcf;
}

.camera-status-monitoring li span {
  font-size: 20px;
  color: #fff;
  margin: 0 5px;
}

.camera-info-wrap {
  position: relative;
  width: 100%;
  height: 260px;
}

.camera-preset-list-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 470px;
}

.cctv-control-wrap {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 262px;
}

.camera-control-speed-label {
  width: 120px;
}

.camera-control-slider {
  width: calc(100% - 120px);
}

.control-slider-wrap {
  width: 200px;
  margin: 10px;
}
</style>

